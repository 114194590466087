<template>
  <v-card-text>
    <v-data-table
      item-key="id"
      checkbox-color="primary"
      v-model="selectedItems"
      fixed-header
      :show-select="conditionViewCheckbox"
      :headers="headerTable"
      :items="data"
      :loading="loadingCriticism"
      :items-per-page="itemsPerPage"
      :page="page"
      :height="data && data.length > 5 ? '290px' : ''"
      @toggle-select-all="handleSelectionChange"
      @update:options="changePage($event)"
    >
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          :value="computeCheckboxValue(item, isSelected)"
          :disabled="computeCheckboxDisabled(item)"
          color="primary"
          @input="select($event)"
        />
      </template>
      <template v-slot:no-data>
        <span>Nenhum item foi encontrado.</span>
      </template>
      <template v-slot:[`item.holderName`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.holderName }}
        </span>
      </template>
      <template v-slot:[`item.beneficiaryName`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.beneficiaryName }}
        </span>
      </template>
      <template v-slot:[`item.field`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.field ? translateField(item.field) : '-' }}
        </span>
      </template>
      <template v-slot:[`item.message`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.message ? item.message : '-' }}
        </span>
      </template>
      <template v-slot:[`item.classification`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.classification && item.classification === 'EXTERNAL' ? 'Restritiva' : 'Restritiva Interna' }}
        </span>
      </template>
      <template v-slot:[`item.criticismCode`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.criticismCode !== null ? item.criticismCode : '-' }}
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span style="white-space: nowrap;">
          {{ item.createdAt ? formatter.formatDateTimeZoneWithHours(item.createdAt) : '-' }}
        </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-btn :style="{ cursor: 'auto' }" dark small rounded :color="formatedStatus(item.status).color">
          {{ formatedStatus(item.status).text }}
        </v-btn>
      </template>
    </v-data-table>

    <div v-if="viewType === 'confirmCorrectionMovement'" class="custom-card mt-10">
      <v-card-text class="pa-0">
        <v-row align="center">
          <v-col class="pl-7" cols="auto" align-self="start">
            <v-icon color="blue">mdi-information-slab-circle-outline</v-icon>
          </v-col>
          <v-col class="px-0">
            <v-card-subtitle class="pa-0">
              <span class="blue--text text-subtitle-2">
                Quando a movimentação é corrigida, um novo registro é gerado e o anterior (criticado) tem o status alterado para "Finalizado com crítica". <br>
                Estes registros ficam disponíveis no seu painel de movimentações para efeito de consulta e histórico.
              </span>
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>
    </div>

    <v-row class="mb-5 mt-15 mx-1" justify="space-between">
      <v-col class="pa-0" cols="12" md="1">
        <v-btn color="primary" large outlined @click="close()">
          <v-icon class="mr-2">{{ viewType === 'confirmCorrectionMovement' ? 'mdi mdi-arrow-left' : 'mdi mdi-close' }}</v-icon>
          {{ viewType === 'confirmCorrectionMovement' ? 'Retornar ao formulário' : 'Fechar' }}
        </v-btn>
      </v-col>

      <v-col align="end" class="pa-0" cols="12" md="11">
        <template v-if="!isRHProtegido && (criticismType === 'INVALID_IN_CARRIER' || criticismType === 'IN_REVIEW') && viewType !== 'confirmCorrectionMovement'">
          <v-btn
            v-if="authorities.hasAuthorityViewButtonSelectedCriticismPending"
            :loading="loadingButtons.selectedCriticismPending"
            class="mr-4"
            outlined
            color="primary"
            large
            @click="selectedAsPending"
            :disabled="disabledSeletecdAsPending"
          >
            <v-icon class="mr-2">mdi mdi-timer-sand-empty</v-icon>
            Marcar selecionados como pendentes
          </v-btn>

          <v-btn
            v-if="authorities.hasAuthorityViewButtonSelectedCriticismResolved"
            :loading="loadingButtons.selectedCriticismResolved"
            class="mr-4"
            outlined
            color="primary"
            large
            @click="selectedAsResolved"
            :disabled="disabledSeletecdAsResolved"
          >
            <v-icon class="mr-2">mdi mdi-check-circle-outline</v-icon>
            Marcar selecionados como resolvidas
          </v-btn>
        </template>

        <v-btn
          v-if="viewType === 'fromMovementRecord'"
          color="primary"
          large
          @click="handlerCorrectionMovement()"
          :loading="loadingButtons.handleCorrectionMovement"
          :disabled="disabledCreateCorrectionMovementButton"
        >
          <v-icon class="mr-2">mdi mdi-plus-circle-outline</v-icon>
          <span v-if="criticismType === 'INVALID_IN_CARRIER'">Criar movimentação de Correção</span>
          <span v-else>Corrigir Movimentação</span>
        </v-btn>

        <template v-if="authorities.hasAuthorityViewButtonResendMovement">
          <v-tooltip bottom :disabled="!disabledResendMovementButton">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="d-inline-block">
                <v-btn
                  v-if="viewType === 'resendMovement'"
                  color="primary"
                  large
                  @click="handlerCorrectionMovement()"
                  :loading="loadingButtons.handleCorrectionMovement"
                  :disabled="disabledResendMovementButton"
                >
                  <v-icon class="mr-2">mdi mdi-arrow-right</v-icon>
                  <span>Reenviar Movimentação</span>
                </v-btn>
              </div>
            </template>
            <span>É necessário resolver as críticas pendentes</span>
          </v-tooltip>
        </template>

        <v-btn
          v-if="viewType === 'confirmCorrectionMovement'"
          color="primary"
          large
          @click="handlerCorrectionMovement()"
          :loading="loadingButtons.handleCorrectionMovement"
        >
          <span>Confirmar a movimentação de correção</span>
          <v-icon class="ml-2">mdi mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>

</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import sdiServiceManager from '@/services-http/sdi/SDIServiceManager';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import DependentContractedPlanService from '@/services-http/contract/DependentContractedPlanService';

export default ({
  name: 'CriticismTabContentComponent',

  data: () => ({
    insuranceCarrierId: Number,
    page: 1,
    itemsPerPage: 5,
    totalPages: 1,
    isFreeMovement: false,
    existMovementRuleVioltationRecord: false,
    criticismType: '',

    movementRecord: {},
    movementRecordParent: {},
    loadingButtons: {
      handleCorrectionMovement: false,
      selectedCriticismPending: false,
      selectedCriticismResolved: false,
    },
    beneficiaryContractedPlan: {
      cpf: null,
      name: null,
      admissionDate: null,
      plans: [],
    },

    selectedItems: [],
    criticismItems: [],
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  props: {
    data: Array,
    loadingCriticism: Boolean,
    authorities: Object,
    movementRecordContext: Object,
    dataSourceColumn: Array,
    viewType: String,
  },

  watch: {
    movementRecordContext: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.movementRecord = newValue.movementRecord;
        this.movementRecordParent = newValue.movementRecordParent;
        this.insuranceCarrierId = newValue.insuranceCarrierId;
        this.isFreeMovement = newValue.isFreeMovement;
        this.isRHProtegido = newValue.isRHProtegido;
        this.criticismType = newValue.criticismType;
      },
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.serviceController();
  },

  computed: {
    headerTable() {
      const commonHeaders = [
        { text: 'Nome do Titular', value: 'holderName', align: 'start', sortable: false },
        { text: 'Nome do Beneficiário', value: 'beneficiaryName', align: 'start', sortable: false },
        { text: 'Campo Criticado', value: 'field', align: 'start', sortable: false },
        { text: 'Mensagem', value: 'message', align: 'start', sortable: false },
        { text: 'Regra', value: 'rule', align: 'start', sortable: false },
      ];

      if (this.criticismType === 'INVALID_IN_CARRIER' || this.criticismType === 'IN_REVIEW') {
        const headers = [
          ...commonHeaders,
          { text: 'Tipo de Crítica', value: 'classification', align: 'start', sortable: false },
          { text: 'Cód.', value: 'criticismCode', align: 'start', sortable: false },
          { text: 'Data/Hora', value: 'createdAt', align: 'start', sortable: false },
          { text: 'Status', value: 'status', align: 'start', sortable: false },
        ];

        const indexToRemove = headers.findIndex((header) => header.value === 'rule');
        if (indexToRemove !== -1) {
          headers.splice(indexToRemove, 1);
        }

        return headers;
      }

      return commonHeaders;
    },
    hasSelectedItems() {
      return Array.isArray(this.selectedItems) && !this.rule.isEmptyArray(this.selectedItems);
    },
    disabledSeletecdAsPending() {
      return this.hasSelectedItems ? this.selectedItems.some((s) => s.status !== 'RESOLVED') : true;
    },
    disabledSeletecdAsResolved() {
      return this.hasSelectedItems ? this.selectedItems.some((s) => s.status !== 'WAITING') : true;
    },
    disabledCreateCorrectionMovementButton() {
      return this.viewType === 'fromMovementRecord' && this.criticismType === 'INVALID_IN_CARRIER' && !(this.data && this.data.some((criticism) => criticism.classification === 'EXTERNAL'));
    },
    disabledResendMovementButton() {
      return this.viewType === 'resendMovement' && this.criticismType === 'IN_REVIEW' && this.data.some((criticism) => criticism.status === 'WAITING');
    },
    viewTypeMapping() {
      return {
        fromMovementRecord: {
          icon: 'mdi mdi-plus-circle-outline',
          buttonText: 'Criar movimentação de Correção',
          arrowIcon: null,
        },
        resendMovement: {
          icon: null,
          buttonText: 'Reenviar Movimentação',
          arrowIcon: null,
        },
        confirmCorrectionMovement: {
          icon: null,
          buttonText: 'Confirmar a movimentação de correção',
          arrowIcon: 'mdi mdi-arrow-right',
        },
      };
    },
    getSelectedViewType() {
      return this.viewTypeMapping[this.viewType];
    },
    icon() {
      return this.viewType === 'fromMovementRecord' ? 'mdi mdi-plus-circle-outline' : null;
    },
    buttonText() {
      switch (this.viewType) {
        case 'fromMovementRecord':
          return 'Criar movimentação de Correção';
        case 'resendMovement':
          return 'Reenviar Movimentação';
        case 'confirmCorrectionMovement':
          return 'Confirmar a movimentação de correção';
        default:
          return '';
      }
    },
    arrowIcon() {
      return this.viewType === 'confirmCorrectionMovement' || this.viewType === 'resendMovement' ? 'mdi mdi-arrow-right' : null;
    },
    conditionViewCheckbox() {
      return this.movementRecordContext.criticismType === 'INVALID_IN_CARRIER' || this.movementRecordContext.criticismType === 'IN_REVIEW';
    },
  },

  methods: {
    serviceController() {
      this.movementRecordService = sdiServiceManager.getMovementRecordService();
      this.movementInsuranceCarrierReturnService = sdiServiceManager.getMovementInsuranceCarrierReturnService();
    },
    computeCheckboxValue(item, isSelected) {
      return this.viewType === 'confirmCorrectionMovement' && item.classification === 'EXTERNAL' ? true : isSelected;
    },
    computeCheckboxDisabled(item) {
      if (this.viewType !== 'resendMovement') {
        if (item && item.classification) {
          return item.classification === 'EXTERNAL';
        }
        return true;
      }
      return false;
    },
    formatedStatus(status) {
      if (status) {
        if (status === 'WAITING') return { color: '#d62136', text: 'Pendente' };
        if (status === 'RESOLVED') return { color: '#079c77', text: 'Resolvida' };
      }
      return status;
    },
    changePage() {
      this.selectedItems = [];
    },
    handleSelectionChange(data) {
      if (Array.isArray(this.selectedItems) && this.rule.isEmptyArray(this.selectedItems)) {
        const foundSelected = data.items.filter((item) => item.classification === 'INTERNAL');
        this.selectedItems = foundSelected;
      } else {
        this.selectedItems = [];
      }
    },
    updateStatusForSelectedItems(newStatus) {
      if (this.hasSelectedItems) {
        const selectedStatus = newStatus === 'RESOLVED' ? 'WAITING' : 'RESOLVED';

        const selectedItemsToUpdate = this.selectedItems.filter((item) => item.status === selectedStatus);

        if (selectedItemsToUpdate.length > 0) {
          const { movementRecordId } = selectedItemsToUpdate[0];

          const arrayToUpdate = selectedItemsToUpdate.map((item) => ({
            movementInsuranceCarrierReturnId: item.id,
            status: newStatus,
          }));

          const jsonData = {
            movementRecordId,
            criticisms: arrayToUpdate,
          };

          this.movementInsuranceCarrierReturnService.Update(jsonData).then(() => {
            this.selectedItems = [];
            this.$emit('loadMovementCriticismByMovementRecordId');
            this.setSnackbarCustomize('success', 'Status atualizado com sucesso');
          }).catch(() => {
            this.setSnackbarCustomize('error', 'Ocorreu um erro ao atualizar o status');
          });
        }
      }
    },
    selectedAsResolved() {
      this.updateStatusForSelectedItems('RESOLVED');
    },
    selectedAsPending() {
      this.updateStatusForSelectedItems('WAITING');
    },
    async handlerCorrectionMovement() {
      this.setLoadingButton('handleCorrectionMovement', true);

      await this.confirmCorrectionMovement();
      await this.createCorrectionMovement();
      await this.resendMovement();

      this.setLoadingButton('handleCorrectionMovement', false);
      this.close();
    },
    async createCorrectionMovement() {
      if (this.viewType !== 'fromMovementRecord') {
        return;
      }

      sessionStorage.setItem('criticismRules', JSON.stringify(this.data));
      sessionStorage.setItem('movementRecord', JSON.stringify(this.movementRecord));

      if(this.movementRecord.beneficiaryType.toUpperCase() === 'holder'.toUpperCase()){   
        const beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
        if(this.movementRecord.contractedPlanId)
          await this.getContractedPlanData(beneficiaryContractedPlanService, this.movementRecord.contractedPlanId);
      }else{
        const dependentContractedPlanService = new DependentContractedPlanService();
        if(this.movementRecord.contractedPlanId)
          await this.getContractedPlanData(dependentContractedPlanService, this.movementRecord.contractedPlanId);
      }

      this.verifyMovementType();
    },
    async getContractedPlanData(service, contractedPlanId){
      await service.FindById(`v2/${contractedPlanId}/contracted_plan_data`).then((response) => {
        if (response && response.data) {
          const holder = response.data;
          sessionStorage.setItem('beneficiaryData', JSON.stringify(holder));
        }
      }).catch(() => {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao localizar os dados do contrato');
      });
    },
    async resendMovement() {
      if (this.viewType !== 'resendMovement') {
        return;
      }

      await this.movementRecordService.ResendMovement(this.movementRecord.id).then(() => {
        this.setSnackbarCustomize('success', 'Movimentação reenviada com sucesso');
        this.$emit('updateMovementTable');
        this.close();
      }).catch(() => {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao reenviar a movimentação');
      });
    },
    async confirmCorrectionMovement() {
      if (this.viewType !== 'confirmCorrectionMovement') {
        return;
      }

      const selectedItemsToUpdate = this.selectedItems.filter((item) => item.classification === 'INTERNAL');
      const internalCriticisms = selectedItemsToUpdate.map((item) => ({ movementCarrierReturnId: item.id }));
      this.$emit('confirmCorrectionMovement', internalCriticisms);
    },
    translateField(field) {
      if (field && field !== null) {
        const comparationField = this.dataSourceColumn.find((element) => element.value === field);
        if (comparationField) {
          return this.formatter.formatToTitleCase(comparationField.label);
        }
      }
      return field;
    },
    setLoadingButton(action, value) {
      this.$set(this.loadingButtons, action, value);
    },
    verifyMovementType() {
      switch (this.movementRecord.movementType) {
        case 'INSERT':
        case 'INSERT_DEPENDENT':
        case 'IMPLANTATION':
          this.adjustedForMovementInsert();
          break;
        case 'UPDATE':
        case 'UPDATE_DEPENDENT':
          this.adjustedForMovementUpdate();
          break;
        case 'DELETE':
        case 'DELETE_DEPENDENT':
          this.adjustedForMovementDelete();
          break;
        default:
          break;
      }
    },
    async adjustedForMovementInsert() {
      if (this.movementRecord.beneficiaryType === 'HOLDER') {
        await this.loadBeneficiaryInfo();
        await this.redirectHolderForInsertAdjusted();
      }
      if (this.movementRecord.beneficiaryType === 'DEPENDENT') {
        await this.redirectDependentForInsertAdjusted();
      }
    },
    async loadBeneficiaryInfo() {
      this.beneficiaryContractedPlan.cpf = this.movementRecord.holderDocumentNumber;
      this.beneficiaryContractedPlan.name = this.movementRecord.holderName;
      this.beneficiaryContractedPlan.admissionDate = this.movementRecord.eventDate;
      this.beneficiaryContractedPlan.plans.push({
        financialGroup: this.movementRecord.financialGroupId ? Number(this.movementRecord.financialGroupId) : null,
        contract: this.movementRecord.contractId ? Number(this.movementRecord.contractId) : null,
        subcontract: this.movementRecord.subContractId ? Number(this.movementRecord.subContractId) : null,
        eligibility: this.movementRecord.contractEligibilityId ? this.movementRecord.contractEligibilityId : null,
        plan: this.movementRecord.plan ? Number(this.movementRecord.plan) : null,
        validityDate: this.movementRecord.startDate ? this.movementRecord.startDate : null,
      });

      sessionStorage.setItem('beneficiaryContractedPlan', JSON.stringify(this.beneficiaryContractedPlan));
    },
    async redirectHolderForInsertAdjusted() {
      const query = this.generateQuery(this.movementRecord);

      if (this.movementRecord.status === 'INVALID_IN_CARRIER') {
        this.redirectRouter('BeneficiaryPlanInformation', query);
      } else {
        const movementRecordIds = this.movementRecord.dependents.length > 0 ? this.movementRecord.dependents.map((dependent) => (dependent.id)) : [];
        movementRecordIds.push(this.movementRecord.id);
        await this.movementRecordService.GetMovementCriticismByMovementRecordIds(movementRecordIds).then((response) => {
          if (response && response.data) {
            this.existMovementRuleVioltationRecord = !response.data.empty;
            this.translateCriticism(response.data, this.movementRecord);
          }
        });
        sessionStorage.removeItem('criticismRules');
        sessionStorage.removeItem('movementRecord');
        sessionStorage.setItem('criticismRules', JSON.stringify(this.criticismItems));
        sessionStorage.setItem('movementRecord', JSON.stringify(this.movementRecord));
        this.redirectRouter('FamilyGroupInclusionUpdate', query);
      }
    },
    async redirectDependentForInsertAdjusted() {
      let query = {};

      if (this.movementRecordParent !== null && typeof this.movementRecordParent !== 'undefined') {
        this.criticismItems = [];
        query = this.generateQuery(this.movementRecordParent);

        const movementRecordIds = this.movementRecordParent.dependents.map((dependent) => (dependent.id));
        movementRecordIds.push(this.movementRecordParent.id);
        await this.movementRecordService.GetMovementCriticismByMovementRecordIds(movementRecordIds).then((response) => {
          if (response && response.data) {
            this.existMovementRuleVioltationRecord = !response.data.empty;
            this.translateCriticism(response.data, this.movementRecordParent);
          }
        });

        sessionStorage.removeItem('criticismRules');
        sessionStorage.removeItem('movementRecord');
        sessionStorage.setItem('criticismRules', JSON.stringify(this.criticismItems));
        sessionStorage.setItem('movementRecord', JSON.stringify(this.movementRecordParent));
        this.redirectRouter('FamilyGroupInclusionUpdate', query);
      } else {
        query = this.generateQuery(this.movementRecord);
        this.redirectRouter('DependentUpdate', query);
      }
    },
    async adjustedForMovementUpdate() {
      let query = {};
      query = this.generateQuery(this.movementRecord);

      if (this.movementRecord.beneficiaryType === 'HOLDER') {
        this.redirectRouter('Alteration', query);
      }
      if (this.movementRecord.beneficiaryType === 'DEPENDENT') {
        this.redirectRouter('DependentAlteration', query);
      }
    },
    async adjustedForMovementDelete() {
      let query = {};
      query = this.generateQuery(this.movementRecord);

      if (this.movementRecord.beneficiaryType === 'HOLDER') {
        this.redirectRouter('HolderDelete', query);
      }
      if (this.movementRecord.beneficiaryType === 'DEPENDENT') {
        this.redirectRouter('DependentDelete', query);
      }
    },
    translateCriticism(criticismItems, movement) {
      if (criticismItems.length > 0) {
        this.criticismItems = [];

        criticismItems.forEach((criticism) => {
          if (criticism.movementRecordId === movement.id) {
            this.pushCriticismData(movement.holderName, movement.insuredName, criticism);
          } else if (movement.dependents.length > 0) {
            const dependentFound = movement.dependents.find((dependent) => dependent.id === criticism.movementRecordId);

            if (typeof dependentFound !== 'undefined') {
              this.pushCriticismData(dependentFound.holderName, dependentFound.insuredName, criticism);
            }
          }
        });
      }
    },
    pushCriticismData(holderName, insuredName, criticism) {
      const criticismData = {
        holderName,
        beneficiaryName: insuredName,
        cardNumber: criticism.cardNumber,
        field: criticism.field ? criticism.field : null,
        fieldValue: criticism.fieldValue,
        id: criticism.id,
        message: criticism.message !== null ? criticism.message : criticism.criticismDescription,
        movementInsuranceCarrierId: criticism.movementInsuranceCarrierId,
        classification: criticism.classification ? criticism.classification : null,
        movementRecordId: criticism.movementRecordId,
        criticismCode: criticism.criticismCode ? criticism.criticismCode : null,
        rule: criticism.rule,
        ruleId: criticism.ruleId,
        createdAt: criticism.createdAt ? criticism.createdAt : null,
        status: criticism.status ? criticism.status : 'WAITING',
      };

      this.criticismItems.push(criticismData);
    },
    generateQuery(movementRecord) {
      return {
        beneficiaryType: movementRecord.beneficiaryType,
        movementType: movementRecord.movementType,
        movementRecordId: movementRecord.id,
        insuranceCarrierId: this.insuranceCarrierId,
        isRHProtegido: this.isRHProtegido,
        freeMovement: this.isFreeMovement,
      };
    },
    close() {
      this.selectedItems = [];
      this.$emit('close');
    },
    setSnackbarCustomize(type, message) {
      this.$emit('setSnackbarCustomize', type, message);
    },
  },
});
</script>

<style scoped>
.custom-card {
  background: #e6f5fe;
  border-radius: 8px;
}
</style>
