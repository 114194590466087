<template>
  <v-dialog :value="showViewCriticismAndInternalNotesModal" persistent max-width="1500">
    <v-card class="pa-2">
      <HeaderComponent
        :headerName="headerName"
        :criticismType="movementRecordContext.criticismType"
        :viewType="viewType"
        @close="close()"
      />

      <TabsComponent
        v-if="viewType !== 'confirmCorrectionMovement'"
        v-model="tab"
        :isRHProtegido="movementRecordContext.isRHProtegido"
        :authorities="authorities"
        :countPendingCriticism="countPendingCriticism"
        :viewType="viewType"
        :criticismType="movementRecordContext.criticismType"
        @changeTab="changeTab()"
      />

      <v-window v-model="tab">
        <v-window-item value="criticism">
          <CriticismTableContentComponent
            :data="criticismData"
            :loadingCriticism="loadingCriticism"
            :authorities="authorities"
            :movementRecordContext="movementRecordContext"
            :dataSourceColumn="dataSourceColumn"
            :viewType="viewType"
            @close="close()"
            @loadMovementCriticismByMovementRecordId="loadMovementCriticismByMovementRecordId"
            @confirmCorrectionMovement="confirmCorrectionMovement"
            @updateMovementTable="updateMovementTable"
            @setSnackbarCustomize="setSnackbarCustomize"
          />
        </v-window-item>

        <v-window-item value="internalNotes">
          <InternalNotesTabContentComponent
            ref="InternalNotesTabContentComponent"
            :movementRecordId="movementRecordContext.movementRecordId"
            @close="close()"
            @setSnackbarCustomize="setSnackbarCustomize"
          />
        </v-window-item>
      </v-window>
    </v-card>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-dialog>
</template>

<script>
import sdiServiceManager from '@/services-http/sdi/SDIServiceManager';
import Formatters from '@/shared/formatters/formatters';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import AuthorityManager from '@/shared/models/authority-manager.model';
import HeaderComponent from './HeaderComponent.vue';
import TabsComponent from './TabsComponent.vue';
import CriticismTableContentComponent from './CriticismTableContentComponent.vue';
import InternalNotesTabContentComponent from './InternalNotesTabContentComponent.vue';

export default ({
  name: 'ViewCriticismAndInternalNotesModal',

  components: {
    HeaderComponent,
    TabsComponent,
    CriticismTableContentComponent,
    InternalNotesTabContentComponent,
    SnackbarCustomize,
  },

  data: () => ({
    tab: 'criticism',
    showViewCriticismAndInternalNotesModal: false,
    loadingCriticism: false,
    isCriticizedBroker: false,
    isCriticizedCarrier: false,
    hasAuthorityViewInternalNoteTab: false,
    hasAuthorityViewButtonSelectedCriticismPending: false,
    hasAuthorityViewButtonSelectedCriticismResolved: false,
    hasAuthorityViewButtonResendMovement: false,
    viewType: '',
    headerName: '',

    authorities: {
      hasAuthorityViewInternalNoteTab: false,
      hasAuthorityViewButtonSelectedCriticismPending: false,
      hasAuthorityViewButtonSelectedCriticismResolved: false,
      hasAuthorityViewButtonResendMovement: false,
    },

    movementRecordContext: {
      holderName: '',
      beneficiaryType: '',
      beneficiaryName: '',
      insuranceCarrierId: '',
      criticismType: '',
      isFreeMovement: false,
      isRHProtegido: false,
      movementRecordId: '',
      movementRecord: {},
      movementRecordParent: {},
    },
    dataSourceColumn: [],
    countPendingCriticism: [],
    criticismData: [],
  }),

  created() {
    this.formatter = new Formatters();
    this.loadAuthorityMovement();
    this.serviceController();
  },

  methods: {
    async loadAuthorityMovement() {
      this.authorities.hasAuthorityViewInternalNoteTab = AuthorityManager.getAuthorityViewInternalNoteTab();
      this.authorities.hasAuthorityViewButtonSelectedCriticismPending = AuthorityManager.getAuthorityViewButtonSelectedCriticismPending();
      this.authorities.hasAuthorityViewButtonSelectedCriticismResolved = AuthorityManager.getAuthorityViewButtonSelectedCriticismResolved();
      this.authorities.hasAuthorityViewButtonResendMovement = AuthorityManager.getAuthorityViewButtonResendMovement();
    },
    serviceController() {
      this.dataSourceService = sdiServiceManager.getDataSourceService();
      this.movementRecordService = sdiServiceManager.getMovementRecordService();
      this.movementInsuranceCarrierReturnService = sdiServiceManager.getMovementInsuranceCarrierReturnService();
    },
    async open(queryParams) {
      this.showViewCriticismAndInternalNotesModal = true;

      this.setupBasicData(queryParams);
      this.validateCriticismType(queryParams);
    },
    setupBasicData(queryParams) {
      this.countPendingCriticism = [];
      const { movementRecord, movementRecordParent, isRHProtegido, viewType } = queryParams;
      this.movementRecordContext = {
        holderName: movementRecord.holderName ? this.formatter.formatToTitleCase(movementRecord.holderName) : '-',
        beneficiaryName: movementRecord.insuredName ? this.formatter.formatToTitleCase(movementRecord.insuredName) : '-',
        isFreeMovement: movementRecord.freeMovement,
        insuranceCarrierId: movementRecord.insuranceCarrierId,
        beneficiaryType: movementRecord.beneficiaryType,
        movementRecordId: movementRecord.id,
        criticismType: movementRecord.status,
        isRHProtegido,
        movementRecord,
        movementRecordParent: movementRecordParent || null,
      };

      const entityType = movementRecord.status === 'INVALID' ? 'Corretora' : 'Operadora';
      this.viewType = viewType;
      this.headerName = this.viewType !== 'confirmCorrectionMovement' ? `Correção de Críticas da ${entityType}` : 'Correção de Movimentação';
    },
    async validateCriticismType() {
      await this.loadDataSourceColumns();
      await this.loadMovementCriticismByMovementRecordId();
    },
    async loadDataSourceColumns() {
      await this.dataSourceService.FindAll().then((response) => {
        const fields = response.data.find((item) => item.value === 'movimentacao');
        if (fields) {
          this.dataSourceColumn = fields.columns;
          sessionStorage.setItem('dataSourceColumn', JSON.stringify(this.dataSourceColumn));
        }
      }).catch(() => {
        this.setSnackbarCustomize('error', 'Ocorreu um erro ao buscar colunas de fonte de dados');
      });
    },
    close() {
      this.criticismData = [];
      this.tab = 'criticism';
      this.showViewCriticismAndInternalNotesModal = false;
    },
    async loadMovementCriticismByMovementRecordId() {
      this.criticismData = [];
      this.countPendingCriticism = [];

      if (this.movementRecordContext.movementRecord.status === 'INVALID') {
        this.handleCriticizedBroker();
      } else {
        this.handleCriticizedCarrier();
      }
    },
    async handleCriticizedBroker() {
      this.setLoading(true);
      sessionStorage.setItem('isCriticizedBroker', true);
      this.isCriticizedBroker = true;
      this.isCriticizedCarrier = false;

      const response = await this.movementRecordService.GetMovementCriticismByMovementRecordId(this.movementRecordContext.movementRecordId);

      if (response?.data?.content?.length > 0) {
        const criticism = response.data.content;
        this.formatCriticismBroker(criticism);
      }
      this.setLoading(false);
    },
    async handleCriticizedCarrier() {
      this.setLoading(true);
      sessionStorage.setItem('isCriticizedCarrier', true);
      this.isCriticizedBroker = false;
      this.isCriticizedCarrier = true;

      const response = await this.movementInsuranceCarrierReturnService.FindCarrierInvalide(this.movementRecordContext.movementRecordId);

      if (response?.data?.length > 0) {
        const criticism = response.data;
        this.formatCriticismCarrier(criticism);
      }
      this.setLoading(false);
    },
    formatCriticismBroker(criticism) {
      criticism.forEach((c) => {
        this.criticismData.push({
          holderName: this.movementRecordContext.holderName,
          beneficiaryName: this.movementRecordContext.beneficiaryName,
          field: c.field ? c.field : null,
          fieldValue: c.fieldValue,
          id: c.id,
          message: c.message ? c.message : null,
          movementInsuranceCarrierId: c.movementInsuranceCarrierId,
          movementRecordId: c.movementRecordId,
          rule: c.rule,
          ruleId: c.ruleId,
          createdAt: c.createdAt ? c.createdAt : null,
        });
      });
    },
    formatCriticismCarrier(criticism) {
      criticism.forEach((c) => {
        this.criticismData.push({
          holderName: this.movementRecordContext.holderName,
          beneficiaryName: this.movementRecordContext.beneficiaryName,
          cardNumber: c.cardNumber,
          field: c.field ? c.field : null,
          fieldValue: c.fieldValue,
          id: c.id,
          message: criticism.message !== null ? c.message : c.criticismDescription,
          movementInsuranceCarrierId: c.movementInsuranceCarrierId,
          classification: c.classification ? c.classification : null,
          movementRecordId: c.movementRecordId,
          criticismCode: c.criticismCode ? c.criticismCode : null,
          rule: c.rule,
          ruleId: c.ruleId,
          createdAt: c.createdAt ? c.createdAt : null,
          status: c.status ? c.status : 'WAITING',
        });
      });

      if (this.movementRecordContext.isRHProtegido) {
        this.criticismData = this.criticismData.filter((c) => c.classification === 'EXTERNAL');
      }

      if (!this.movementRecordContext.isRHProtegido && this.viewType === 'resendMovement') {
        this.criticismData = this.criticismData.filter((c) => c.classification === 'INTERNAL');
      }

      this.counterPendingCriticism();
    },
    counterPendingCriticism() {
      this.countPendingCriticism = this.criticismData.filter((criticism) => criticism.status === 'WAITING');
    },
    changeTab() {
      setTimeout(() => {
        this.$refs.InternalNotesTabContentComponent.loadInternalNotes();
      }, 200);
    },
    setLoading(value) {
      this.loadingCriticism = value;
    },
    setSnackbarCustomize(type, message) {
      this.$emit('setSnackbarCustomize', type, message);
    },
    confirmCorrectionMovement(data) {
      this.$emit('confirmCorrectionMovement', data);
    },
    updateMovementTable() {
      this.$emit('updateMovementTable');
    },
  },
});
</script>
